<script lang="ts" setup>
import beta from '@svg/misc/beta.svg'

const props = defineProps<{
  size?: number
}>()

const sizeFactor = computed(() => props.size || 1)

const logoHeight = computed(() => {
  return sizeFactor.value * 3.125 + 'em'
})

const logoCutHeight = computed(() => {
  return sizeFactor.value * 2.875 + 'em'
})

const betaLeft = computed(() => {
  return sizeFactor.value * 0.1 + 'em'
})

const betaHeight = computed(() => {
  return sizeFactor.value * 0.9 + 'em'
})
</script>

<template>
  <NuxtLink :to="localeLink('/')" class="logo">
    <div style="text-wrap: nowrap">
      <LayoutsLogo :height="logoHeight" />
      <LayoutsLogoCut :height="logoCutHeight" style="color: white" />
      <VTooltip activator="parent" location="bottom" :text="$t('Back to Home')" />
      <beta class="beta" />
    </div>
  </NuxtLink>
</template>
<style lang="css" scoped>
.beta {
  position: relative;
  bottom: 0em;
  left: v-bind(betaLeft);
  height: v-bind(betaHeight);
}
.logo {
}
</style>
